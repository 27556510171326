@use '../../../assets/theming/colours.scss' as colours;


$icon-warn-color: colours.get-color-from-palette(colours.$apollo-red-palette, 500);

:root {
  --mdc-shape-small: 8px;

  input[type=password] {
    letter-spacing: 1.5px;
  }

  mat-label + mat-form-field {
    margin-top: 8px;
  }
  mat-form-field {

    &.mat-form-field-invalid {
      mat-icon {
        color: $icon-warn-color;
      }
    }
  }
}