@mixin mat-button {
  [mat-flat-button] {
    height: 44px !important;
    padding: 0 24px !important;

    &.full-width {
      width: 100%;
    }
    mat-icon {
      &[prefix] {
        margin-left: -8px;
        margin-right: 0;
      }
      &[postfix] {
        margin-right: -12px;
      }
    }
  }
  [mat-stroked-button] {
    padding: 0 24px;
    font-size: 14px !important;
    font-weight: 600 !important;
    height: 44px !important;
    mat-icon {
      height: 24px !important;
      width: 24px !important;
      font-size: 24px !important;
      &[prefix] {
        margin-left: -4px;
        margin-right: 8px;
      }
      &[postfix] {
        margin-right: -12px;
      }
    }
  }
}

.is-responsive {
  [mat-stroked-button][responsive] {
    .mdc-button__label {
      display: none;
    }
    mat-icon {
      margin: 0 !important;
    }
  }
}

@include mat-button;