// These breakpoints are no longer in sync with Tailwind breakpoints
@mixin mobile {
  @media screen and (max-width: 599.98px) and (orientation: portrait), (max-width: 959.98px) and (orientation: landscape) {
    @content;
  }
}
@mixin tablet {
  @media screen and (min-width: 600px) and (max-width: 839.98px) and (orientation: portrait), (min-width: 960px) and (max-width: 1279.98px) and (orientation: landscape) {
    @content;
  }
}
@mixin desktop {
  @media screen and (min-width: 840px) and (orientation: portrait), (min-width: 1280px) and (orientation: landscape) {
    @content;
  }
}
@mixin gt-xs {
  @media screen and (min-width: 600px) {
    @content;
  }
}
@mixin gt-sm {
  @media screen and (min-width: 960px) {
    @content;
  }
}
@mixin lt-md {
  @media screen and (max-width: 959.98px) {
    @content;
  }
}

/*
const Breakpoints = {
  XSmall: '(max-width: 599.98px)',
  Small: '(min-width: 600px) and (max-width: 959.98px)',
  Medium: '(min-width: 960px) and (max-width: 1279.98px)',
  Large: '(min-width: 1280px) and (max-width: 1919.98px)',
  XLarge: '(min-width: 1920px)',
  Handset: '(max-width: 599.98px) and (orientation: portrait), ' +
      '(max-width: 959.98px) and (orientation: landscape)',
  Tablet: '(min-width: 600px) and (max-width: 839.98px) and (orientation: portrait), ' +
      '(min-width: 960px) and (max-width: 1279.98px) and (orientation: landscape)',
  Web: '(min-width: 840px) and (orientation: portrait), ' +
      '(min-width: 1280px) and (orientation: landscape)',
  HandsetPortrait: '(max-width: 599.98px) and (orientation: portrait)',
  TabletPortrait: '(min-width: 600px) and (max-width: 839.98px) and (orientation: portrait)',
  WebPortrait: '(min-width: 840px) and (orientation: portrait)',
  HandsetLandscape: '(max-width: 959.98px) and (orientation: landscape)',
  TabletLandscape: '(min-width: 960px) and (max-width: 1279.98px) and (orientation: landscape)',
  WebLandscape: '(min-width: 1280px) and (orientation: landscape)',
};
*/