
$app-container-padding: 24px;

@mixin app-container {
  // add a generic set of rules to each child page in the router

  &.is-responsive::ng-deep > router-outlet + * {
    // margin-top: 75px;
    width: 100%;
    display: flex;
    flex-direction: column !important;
    flex: 1 1 100%;
    padding: 16px;
    // padding-bottom: 40px; // offset for top navbar in mobile mode

  }

  &:not(.is-responsive)::ng-deep > router-outlet + * {
    // margin-top: 75px;
    -webkit-overflow-scrolling: touch;
    padding: 24px;

    width: 100%;
    flex-direction: column !important;
    flex: 1 1 100%;
    display: flex;
    align-items: center;
    > * {
      max-width: 1000px;
      width: 100%;
    }
  }
}

.scroll-wrapper {
  display: flex;
  position:relative;
  height: 100%;
  overflow: auto;
  width: 100%;

  > div {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
  }
}

.app-container {
  max-width: 1000px;
  width: 100%;
}

.mobile-app-container {
  width: 100%;
}
