@use '@angular/material' as mat;
@use 'colours.scss' as colours;
@use 'material-icons/iconfont/filled.css';
@use 'material-icons/iconfont/outlined.css';
@use 'helpers/columns.scss'; // DEPRECATED, try to replace with tailwind
@use 'helpers/typography.scss' as typography;
@use 'components/mat-form-field.scss';
@use 'components/mat-button.scss';
@use 'components/mat-table.scss';
@use 'components/mat-chip.scss';
@use 'components/mat-checkbox.scss';
@use 'components/layout.scss';
@use 'components/dialog.scss';
@use 'components/form.scss';
@use 'components/mat-snackbar.scss';
@use 'components/page-loader.scss';


@include mat.core();
@include typography.typograhpy();

$apollo-app-primary: mat.m2-define-palette(colours.$apollo-primary-palette, 500);
$apollo-app-accent: mat.m2-define-palette(colours.$apollo-accent-palette, 500, 100, 500);
$apollo-app-warn: mat.m2-define-palette(mat.$m2-red-palette);

$my-theme: mat.m2-define-light-theme((
 color: (
    primary: $apollo-app-primary,
    accent: $apollo-app-accent,
    warn: $apollo-app-warn
 )
));

@include mat.all-component-themes($my-theme);


.color-neutral-200 {
   color: colours.get-color-from-palette(colours.$apollo-neutral-palette, 200);
}
.color-primary-100 {
   color: colours.get-color-from-palette(colours.$apollo-primary-palette, 100);
}
.bg-color-primary-100 {
   background-color: colours.get-color-from-palette(colours.$apollo-primary-palette, 100);
}
.color-warn {
   color: colours.get-color-from-palette(colours.$apollo-red-palette, 500);
}
// Maybe add our colours to taildwind config ? so we can use that system for overrides
.outline-primary-200 {
   outline-color: colours.get-color-from-palette(colours.$apollo-primary-palette, 200) !important;
}